import React from "react"

import Layout from "../components/layout"
import { graphql } from "gatsby"

const NotFoundPage = ({
  data: { contentfulHeader, contentfulFooter, contentfulNotFoundPage },
  uri,
}) => {
  const {
    pageTitle,
    pageDescription: { pageDescription },
    pageImage,
  } = contentfulNotFoundPage

  return (
    <Layout
      header={contentfulHeader}
      footer={contentfulFooter}
      pageMeta={{ pageTitle, pageDescription, pageImage }}
    >
      {/*<SEO title="404: Not found" />*/}
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query {
    #    site {
    #      siteMetadata {
    #        author
    #        siteUrl
    #      }
    #    }
    contentfulHeader {
      ...HeaderInformation
    }
    contentfulFooter {
      ...FooterInformation
    }

    contentfulNotFoundPage {
      pageTitle
      pageDescription {
        pageDescription
      }
      pageImage {
        localFile {
          childImageSharp {
            fixed(width: 1200) {
              width
              height
              src
            }
          }
        }
      }
    }
  }
`
